<template>
  <div>
    <p class="card-text">{{$t('Constructor.PropWidgets.TooltipPositionWidget.Info')}}
      <b-button @click="resetPosition" variant="link" size="sm" class="font-size-11">
        {{$t('Constructor.PropWidgets.TooltipPositionWidget.Reset')}}
      </b-button>
    </p>
    <p class="card-text mb-0">{{$t('Constructor.PropWidgets.TooltipPositionWidget.Elem')}}:</p>
    <b-list-group>
<!--      <b-list-group-item-->
<!--          class="p-1 border-0"-->
<!--          @click="dataObj.data.type = 'target'"-->
<!--      >-->
<!--        <b-form-radio-->
<!--            name="CssSelectorWidget"-->
<!--            size="sm"-->
<!--            v-model="dataObj.data.type"-->
<!--            value="target"-->
<!--        >-->
<!--          <p class="mb-0">Селектор место вывода виджета на сайте</p>-->
<!--          <small class="text-muted">{{elem.main.target.cssSelector}}</small>-->
<!--        </b-form-radio>-->
<!--      </b-list-group-item>-->

<!--                @click="dataObj.data.type = 'manual'"-->
      <b-list-group-item
          class="p-1 border-0"
          @click="$set(dataObj.data, 'type', 'manual')"
      >
        <b-form-radio
            name="CssSelectorWidget"
            size="sm"
            v-model="dataObj.data.type"
            value="elemId"
        >{{$t('Constructor.PropWidgets.TooltipPositionWidget.ElemId')}}</b-form-radio>
        <b-form-radio
            name="CssSelectorWidget"
            size="sm"
            v-model="dataObj.data.type"
            value="manual"
        >{{$t('Constructor.PropWidgets.TooltipPositionWidget.YourOwnVersion')}}</b-form-radio>
      </b-list-group-item>
    </b-list-group>

    <b-form-group
        v-if="dataObj.data.type === 'manual'"
        label-cols-sm="4"
        :label = "$t('Constructor.PropWidgets.TooltipPositionWidget.CssSelector')"
        label-for="main-target-css"
        class="mt-2"
    >
      <b-input-group >
        <b-form-textarea
            v-model="dataObj.data.css_selector"
            :placeholder="$t('General.Form.NoSet')"
            class="propField"
        ></b-form-textarea>
      </b-input-group>
    </b-form-group>

    <b-form-group
        v-if="dataObj.data.type === 'elemId'"
        label-cols-sm="4"
        :label = "$t('Constructor.PropWidgets.TooltipPositionWidget.ElemId')"
        label-for="main-target-css"
        class="mt-2"
    >
      <b-input-group >
        <b-form-select
            :options="getAllElements()"
            v-model="dataObj.data.css_selector"
        ></b-form-select>
      </b-input-group>
    </b-form-group>

    <div class="mt-1 mb-2 text-right">
<!--      <b-form-text class="col-7 pt-2">-->
<!--        {{$t('Constructor.PropWidgets.TooltipPositionWidget.CssSelectorInfo')}}-->
<!--        <i class="fas  fa-arrow-right"></i>-->
<!--      </b-form-text>-->
      <b-button class="font-size-11" variant="dark" size="sm" v-on:click.stop="previewTag" >
        {{$t('Constructor.PropWidgets.TooltipPositionWidget.SelectSelector')}}
          <i class="fas  fa-arrow-right"></i>
      </b-button>
    </div>

    <b-form-group
        label-cols-sm="5"
        :label="$t('Constructor.PropWidgets.TooltipPositionWidget.WindowLocation')"
        size="sm"
        class="mb-0"
    >
      <b-input-group size="sm">
        <b-form-select
            :options="placements"
            v-model="dataObj.data.placement"
        ></b-form-select>
      </b-input-group>
    </b-form-group>

<!--    <b-form-group-->
<!--        label-cols-sm="5"-->
<!--        label="skidding"-->
<!--        class="mt-2"-->
<!--        size="sm"-->
<!--    >-->
<!--      <b-input-group >-->
<!--        <b-form-input-->
<!--            v-model="dataObj.data.skidding"-->
<!--        ></b-form-input>-->
<!--      </b-input-group>-->
<!--    </b-form-group>-->

    <b-form-group
        label-cols-sm="5"
        :label="$t('Constructor.PropWidgets.TooltipPositionWidget.Distance')"
        size="sm"
    >
      <b-input-group size="sm">
        <b-form-input
            v-model="dataObj.data.distance"

        ></b-form-input>
      </b-input-group>
    </b-form-group>


    <h6 class="card-text mt-4">{{$t('Constructor.PropWidgets.TooltipPositionWidget.PointingArrow')}} <i
        v-b-tooltip.hover
        :title="$t('Constructor.PropWidgets.TooltipPositionWidget.ArrowTooltip')"
        class="far fa-question-circle"></i></h6>
<!--    <p class="card-text">-->
<!--      Добавьте подходящую стрелку из геометрических фигур (стрелка должна указывать вниз) и выберете этот элемент в поле ниже-->
<!--    </p>-->

    <div class="text-right">
<!--      <small class="text-muted">{{$t('Constructor.PropWidgets.TooltipPositionWidget.NewArrow')}}</small>-->
      <b-button size="sm" class="ml-2 font-size-11" variant="dark" v-on:click.stop="addArrow" >
        {{$t('Constructor.PropWidgets.TooltipPositionWidget.AddArrow')}}
      </b-button>
    </div>

    <b-form-group
        label-cols-sm="3"
        :label="$t('Constructor.PropWidgets.TooltipPositionWidget.Arrow')"
        class="mt-2"
        size="sm"
    >

<!--      {name: ['arrowDown']}-->
      <b-input-group class="">
        <b-form-select
            :options="getArrToOptionsFromElems(getAllChildElem(settingConstructor.drags.activeElem, {}, false ))"
            v-model="dataObj.data.arrowElemId"
            @change="applyArrowSettings"
        ></b-form-select>
      </b-input-group>
    </b-form-group>

    <p class="card-text small" v-html="$t('Constructor.PropWidgets.TooltipPositionWidget.InfoArrow')"></p>

<!--    <div v-if="dataObj.data.arrowElemId">-->
<!--      <p class="card-text small">-->
<!--        {{$t('Constructor.PropWidgets.TooltipPositionWidget.SettingInfo')}}-->
<!--      </p>-->
<!--      <b-row>-->
<!--        <b-col-->
<!--            v-for="(index, side) in dataObj.data.arrowMargin"-->
<!--            :key="side"-->
<!--        >-->
<!--          <b-button-->
<!--              size="sm"-->
<!--              variant="outline-primary"-->
<!--              data-toggle="buttons"-->
<!--              class="w-100"-->
<!--              @click="previewArrowPos(side)">-->
<!--            {{side}}-->
<!--          </b-button>-->

<!--          <b-form-input-->
<!--              size="sm"-->
<!--              class="mt-2"-->
<!--              type="number"-->
<!--              v-model="dataObj.data.arrowMargin[side]"-->
<!--              @change="changeArrowIndent(side)"-->
<!--              @click="previewArrowPos(side)"-->
<!--          ></b-form-input>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--    </div>-->

  </div>
</template>

<script>
// import TagDataServerHelper from "@/helpers/constructor/TagDataServerHelper";
import HistoryHelper from "@/helpers/constructor/HistoryHelper";
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import lodash from "lodash";
import {PatternElems} from "@/helpers/constructor/patterns/PatternElems";
import ElemStyleHelper from '@/helpers/constructor/ElemStyleHelper'
import TagDataPreviewHelper from "@/helpers/constructor/TagDataPreviewHelper";

export default {
  name: "TooltipPositionWidget",
  props: ["tagData", "settingConstructor", "newAction", "dataObj", "actionIndex"],
  data() {
    return {
      placements: [
        'auto',
        // 'auto-start',
        // 'auto-end',
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end'
      ],
      dataPattern: {
        type: 'manual',
        css_selector: null,
        arrowElemId: null,
        placement: 'auto',
        skidding: 0,
        distance: 4,
        arrowMargin: {
          top: null,
          bottom: null,
          left: null,
          right: null
        }
      }
    }
  },
  created() {
    // console.log(this.dataObj.data, this.dataPattern)
    if (this.dataObj.data.type === undefined) {
      this.dataObj.data = this.dataPattern
    }
  },
  computed: {
    elem: function() {
      return this.tagData.htmlElements[this.settingConstructor.drags.activeElem]
    }
  },
  methods: {
    ...TagDataEditService,
    ...TagDataGetElemsHelper,
    ...HistoryHelper,
    // getAllElements() {
    //   if (this.dataObj.data.elemId !== null) {
    //     return this.getAllElementsToOptions()
    //   }
    //   return [...[{value: null, text: 'Выберите элемент'}], ...this.getAllElementsToOptions()]
    // },
    getAllElements() {
      return [...[{value: null, text: this.$t('General.Base.SelectItem')}], ...this.getAllElementsToOptions()]
    },
    previewTag() {
      // TagDataServerHelper.saveTag(this.tagData, this.settingConstructor)

      TagDataPreviewHelper.getCssSelector(this.tagData, this.settingConstructor, this.applyGetDataFromSearchSelector)
          .then(this.applyGetDataFromSearchSelector)
    },
    applyGetDataFromSearchSelector(data) {
      if (data.cssSelector !== undefined) {
        this.HistorySet(this.dataObj.data, 'css_selector', data.cssSelector)
        // this.dataObj.data.css_selector = data.cssSelector
      }
    },
    resetPosition() {
      let actions = [
        {obj: this.elem.css, nameProp: 'left',val: {val: 0, unit: 'px'}, type: 'set'},
        {obj: this.elem.css, nameProp: 'top',val: {val: 0, unit: 'px'}, type: 'set'},
        {obj: this.elem.css, nameProp: 'right', val: null, type: 'delete'},
        {obj: this.elem.css, nameProp: 'bottom', val: null, type: 'delete'},
        {obj: this.elem.css, nameProp: 'position', val: 'absolute', type: 'set'},
        {obj: this.elem.css, nameProp: 'width', val: 'auto', type: 'set'},
        {obj: this.elem.css, nameProp: 'height', val: 'auto', type: 'set'},
        {obj: this.elem.css, nameProp: 'maxWidth', val: null, type: 'delete'},
        {obj: this.elem.css, nameProp: 'maxHeight', val: null, type: 'delete'},
      ]
      let array = ['margin','marginLeft','marginRight','marginTop','marginBottom']
      array.forEach((elem) => {
        actions.push({obj: this.elem.css, nameProp: elem, val: null, type: 'delete'})
      })

      if (this.elem.css.transform !== undefined && this.elem.css.transform.translateX !== undefined ) {
        actions.push({obj: this.elem.css.transform, nameProp: 'translateX', val: null, type: 'delete'})
      }
      if (this.elem.css.transform !== undefined && this.elem.css.transform.translateY !== undefined ) {
        actions.push({obj: this.elem.css.transform, nameProp: 'translateY', val: null, type: 'delete'})
      }

      this.HistorySetPackage(actions)
    },
    applyArrowSettings() {

      if (!this.dataObj.data.arrowElemId) {
        return
      }
      this.previewArrowPos('top')
      this.previewArrowPos('left')
      this.previewArrowPos('right')
      this.previewArrowPos('bottom')
      // let arrowElem = this.tagData.htmlElements[this.dataObj.data.arrowElemId]
      // let arrowParent = this.tagData.htmlElements[this.settingConstructor.activeMainElemId]
      //
      // if (arrowElem.parent !== this.settingConstructor.activeMainElemId) {
      //   this.TDESchangeElemTree(this.dataObj.data.arrowElemId, this.settingConstructor.activeMainElemId)
      // }

      // const elemContTrue = document.getElementById('elemContTrue_' + this.dataObj.data.arrowElemId)
      // const data = elemContTrue.getBoundingClientRect()
      //
      // let border = arrowParent.css.borderWidth !== undefined ? arrowParent.css.borderWidth.val : 0
      // if (arrowParent.css.borderBottomWidth !== undefined) {
      //   border = arrowParent.css.borderBottomWidth.val
      // }


      // let bottom = - (Math.round(data.height) + border)

      // let actions = [
      //   {obj: arrowElem.css, nameProp: 'left',val: {val: 50, unit: '%'}, type: 'set'},
      //   {obj: arrowElem.css, nameProp: 'bottom',val: {val: bottom, unit: 'px'}, type: 'set'},
      //   {obj: arrowElem.css, nameProp: 'right', val: null, type: 'delete'},
      //   {obj: arrowElem.css, nameProp: 'top', val: null, type: 'delete'}
      // ]
      // let array = ['margin','marginLeft','marginRight','marginTop','marginBottom']
      // array.forEach((elem) => {
      //   actions.push({obj: arrowElem.css, nameProp: elem, val: null, type: 'delete'})
      // })
      // this.HistorySetPackage(actions)
    },
    addArrow() {
      let elemArrow = lodash.cloneDeep(PatternElems.arrowDown)
      elemArrow = new ElemStyleHelper().getStyleElem(elemArrow)
      this.dataObj.data.arrowElemId = this.TDEScreateElem(elemArrow, this.settingConstructor.activeMainElemId)

      // this.previewArrowPos('top')
      // this.previewArrowPos('left')
      // this.previewArrowPos('right')
      // this.previewArrowPos('bottom')
    },
    previewArrowPos(pos) {
      this.settingConstructor.showBordersElem = false

      let arrowElem = this.tagData.htmlElements[this.dataObj.data.arrowElemId]
      let arrowParent = this.tagData.htmlElements[this.settingConstructor.activeMainElemId]

      if (arrowElem.parent !== this.settingConstructor.activeMainElemId) {
        this.TDESchangeElemTree(this.dataObj.data.arrowElemId, this.settingConstructor.activeMainElemId)
      }

      this.$set(arrowElem.css, 'transform', {})

      let actions = [
        {obj: arrowElem.css, nameProp: 'position', val: 'absolute', type: 'set'},
        {obj: arrowElem.css, nameProp: 'left', val: null, type: 'delete'},
        {obj: arrowElem.css, nameProp: 'bottom',val: null, type: 'delete'},
        {obj: arrowElem.css, nameProp: 'right', val: null, type: 'delete'},
        {obj: arrowElem.css, nameProp: 'top', val: null, type: 'delete'}
      ]
      let array = ['marginLeft','marginRight','marginTop','marginBottom']
      array.forEach((elem) => {
        actions.push({obj: arrowElem.css, nameProp: elem, val: null, type: 'delete'})
      })

      let indentArrow = - arrowElem.css.borderTopWidth.val
      let indent = 0

      if (pos === 'top') {
        let border = arrowParent.css.borderTopWidth === undefined ? 0 : arrowParent.css.borderTopWidth.val
        indent = -border + indentArrow
        if (this.dataObj.data.arrowMargin.top !== null) {
          indent = this.dataObj.data.arrowMargin.top
        }
        actions =  actions.concat([
          {obj: arrowElem.css, nameProp: 'top',val: {val: indent, unit: 'px'}, type: 'set'},
          {obj: arrowElem.css, nameProp: 'left',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: arrowElem.css.transform, nameProp: 'rotate',val:{val: 180, unit: 'deg'}, type: 'set'}
        ])
      } else if (pos === 'bottom') {
        let border = arrowParent.css.borderBottomWidth === undefined ? 0 : arrowParent.css.borderBottomWidth.val
        indent = -border + indentArrow
        if (this.dataObj.data.arrowMargin.bottom !== null) {
          indent = this.dataObj.data.arrowMargin.bottom
        }
        actions =  actions.concat([
          {obj: arrowElem.css, nameProp: 'bottom',val: {val: indent, unit: 'px'}, type: 'set'},
          {obj: arrowElem.css, nameProp: 'left',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: arrowElem.css.transform, nameProp: 'rotate',val:{val: 0, unit: 'deg'}, type: 'set'}
        ])
      } else if (pos === 'left') {
        let border = arrowParent.css.borderLeftWidth === undefined ? 0 : arrowParent.css.borderLeftWidth.val
        indent = - border + indentArrow - arrowElem.css.borderRightWidth.val / 2
        if (this.dataObj.data.arrowMargin.left !== null) {
          indent = this.dataObj.data.arrowMargin.left
        }
        actions =  actions.concat([
          {obj: arrowElem.css, nameProp: 'left',val: {val: indent, unit: 'px'}, type: 'set'},
          {obj: arrowElem.css, nameProp: 'top',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: arrowElem.css.transform, nameProp: 'rotate',val:{val: 90, unit: 'deg'}, type: 'set'}
        ])
      } else if (pos === 'right') {
        let border = arrowParent.css.borderRightWidth === undefined ? 0 : arrowParent.css.borderRightWidth.val
        indent = -border + indentArrow - arrowElem.css.borderRightWidth.val / 2
        if (this.dataObj.data.arrowMargin.right !== null) {
          indent = this.dataObj.data.arrowMargin.right
        }
        actions =  actions.concat([
          {obj: arrowElem.css, nameProp: 'right',val: {val: indent, unit: 'px'}, type: 'set'},
          {obj: arrowElem.css, nameProp: 'top',val: {val: 50, unit: '%'}, type: 'set'},
          {obj: arrowElem.css.transform, nameProp: 'rotate',val:{val: 270, unit: 'deg'}, type: 'set'}
        ])
      }
      this.HistorySetPackage(actions)

      if (this.dataObj.data.arrowMargin[pos] === null) {
        this.dataObj.data.arrowMargin[pos] = indent
      }
    },
    changeArrowIndent(pos) {
      let arrowElem = this.tagData.htmlElements[this.dataObj.data.arrowElemId]
      this.$set(arrowElem.css, pos, {val: this.dataObj.data.arrowMargin[pos], unit: 'px'})
    }
  }
}
</script>

<style scoped>

</style>
